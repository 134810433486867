<template>
	<div>
		<v-btn color="deep-orange accent-4" class="float-right" rounded text large @click="reportProblemMenu = true">
			<v-icon class="mr-2"> mdi-bug-outline </v-icon>{{ $t('explorer.reportProblem') }}
		</v-btn>

		<v-dialog v-model="reportProblemMenu" persistent width="500">
			<v-card rounded="xl">
				<v-card-title>
					<v-icon color="deep-orange accent-4" class="mr-2"> mdi-bug-outline </v-icon> {{ $t('explorer.reportProblem') }}
					<v-spacer />
					<v-btn icon @click="reportProblemMenu = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-form ref="form" v-model="valid">
						<v-select
							v-model="degree"
							:label="$t('degrees.degree')"
							:items="degreesItems"
							item-text="name"
							item-value="id"
							chips
							return-object
							outlined
							rounded
							hide-details="auto"
							class="pb-3"
							:rules="rules.degree"
						>
							<template v-slot:selection="data">
								<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
									{{ data.item.name }}
								</v-chip>
							</template>
							<template v-slot:item="data">
								<v-list-item-content>
									<v-list-item-title style="white-space: normal"> ({{ data.item.type }}) {{ data.item.name }} </v-list-item-title>
								</v-list-item-content>
							</template>
						</v-select>

						<v-select
							v-model="reason"
							:label="$t('explorer.reportProblemReason')"
							:items="reasons"
							outlined
							rounded
							hide-details="auto"
							class="pb-3"
							:rules="rules.reason"
						/>

						<template v-if="reason == this.$t('explorer.reportProblemReasonOther')">
							<v-text-field
								v-model="reasonOther"
								:label="$t('explorer.reportProblemReasonOther')"
								:counter="reasonMaxLength"
								:rules="rules.reasonOther"
							/>
						</template>

						<v-textarea
							v-model="message"
							:label="$t('contact.message')"
							:rules="rules.message"
							outlined
							rounded
							hide-details="auto"
							class="pb-3"
							:counter="messageMaxLength"
							auto-grow
						/>
					</v-form>
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn color="primary" :loading="loading" rounded text @click="callReportProblem()">
						{{ $t('contact.send') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'ExplorerReportProblemMenu',
	data() {
		return {
			reportProblemMenu: false,
			loading: false,
			valid: false,
			reason: '',
			reasons: [
				this.$t('explorer.reportProblemReason1'),
				this.$t('explorer.reportProblemReason2'),
				this.$t('explorer.reportProblemReason3'),
				this.$t('explorer.reportProblemReason4'),
				this.$t('explorer.reportProblemReason5'),
				this.$t('explorer.reportProblemReason6'),
				this.$t('explorer.reportProblemReason7'),
				this.$t('explorer.reportProblemReasonOther'),
			],
			reasonOther: '',
			message: '',
			degree: null,
			rules: {
				reason: [(v) => !!v || this.$t('rules.required'), (v) => (v && this.reasons.includes(v)) || this.$t('rules.notSupported')],
				reasonOther: [
					(v) => {
						if (this.reason == this.$t('explorer.reportProblemReasonOther')) return !!v || this.$t('rules.required')
						else return true
					},
					(v) => {
						if (this.reason == this.$t('explorer.reportProblemReasonOther'))
							return (v && v.length <= this.reasonMaxLength) || this.$t('rules.length', { length: this.reasonMaxLength })
						else return true
					},
				],
				message: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.messageMaxLength) || this.$t('rules.length', { length: this.messageMaxLength }),
				],
				degree: [(v) => !!v || this.$t('rules.required'), (v) => (v && this.checkDegree(v) == v) || this.$t('rules.notSupported')],
			},
		}
	},
	computed: {
		degreesItems() {
			const items = []
			this.faculties.forEach((faculty) => {
				items.push({ header: faculty.name })
				faculty.degrees.forEach((degree) => {
					items.push(degree)
				})
				items.push({ divider: true })
			})
			return items
		},
		reasonMaxLength() {
			return 255
		},
		messageMaxLength() {
			return 1024 * 10
		},
		...mapGetters({
			faculties: 'faculties/faculties',
		}),
	},
	created() {
		this.fetchFaculties()
	},
	methods: {
		checkDegree(degree) {
			var found = null
			this.faculties.forEach((faculty) => {
				if (faculty.degrees.includes(degree)) {
					found = degree
				}
			})
			return found
		},
		callReportProblem() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.reportProblem({
				reason: this.reason == this.$t('explorer.reportProblemReasonOther') ? this.reasonOther : this.reason,
				message: this.message,
				degreeID: this.degree.id,
			}).then(() => {
				this.reportProblemMenu = false
				this.loading = false
			})
		},
		...mapActions('explorer', ['reportProblem']),
		...mapActions('faculties', ['fetchFaculties']),
	},
}
</script>
